import { useEffect } from 'react';
import clsx from 'clsx';

import { usePasswordValidation } from 'views/Auth/hooks';
import { NIcon } from '@innersource-nn/novoui-react';

type PasswordRequirementsProps = {
    password: string;
    setIsPasswordValid: (isPasswordValid: boolean) => void;
    isFormTouched: boolean;
};

export default function PasswordValidation({
    password,
    setIsPasswordValid,
    isFormTouched,
}: PasswordRequirementsProps) {
    const passwordValidation = usePasswordValidation(password);

    useEffect(() => {
        setIsPasswordValid(
            passwordValidation.every(({ condition }) => condition),
        );
    }, [passwordValidation, setIsPasswordValid]);

    const getIcon = (condition: boolean) => {
        if (isFormTouched) {
            return condition ? 'completed' : 'delete';
        } else {
            return 'circleRemove';
        }
    };

    const getIconColor = (condition: boolean) => {
        if (isFormTouched) {
            return condition ? '#3F9C35' : '#e6553f';
        } else {
            return 'rgb(0, 25, 101)';
        }
    };

    return (
        <div className="flex flex-col gap-y-4">
            {passwordValidation.map(({ text, condition }) => (
                <div className="flex gap-2 items-center">
                    <NIcon
                        iconName={getIcon(condition)}
                        size={13}
                        className="text-forest-green"
                        fill={getIconColor(condition)}
                    />
                    <span
                        className={clsx(
                            'text-left text-xs ml-2',
                            isFormTouched &&
                                (condition
                                    ? 'text-forest-green'
                                    : 'text-lava-red-600'),
                        )}
                    >
                        {text}
                    </span>
                </div>
            ))}
        </div>
    );
}
