const setGlobalErrorHandler = () => {
    const errorHandler = (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        err?: Error,
    ): boolean => {
        if (!err) {
            const errEvt = event as ErrorEvent;
            err = errEvt.error;
        }
        const msg = err?.message || 'Unknown Error';
        window.location.replace(`/error?msg=${encodeURIComponent(msg)}`);

        return true;
    };

    window.addEventListener('error', errorHandler);

    // returns the unsetter in case the caller needs it
    return () => {
        window.removeEventListener('error', errorHandler);
    };
};

export default setGlobalErrorHandler;
