import React, { useState } from 'react';
import { NInput, NButton } from '@innersource-nn/novoui-react';
import { MIN_PASSWORD_LENGTH } from 'views/Auth/utils/validation';

type Props = {
    newPassword: string;
    setNewPassword: (newPassword: string) => void;
    onSubmit: () => void;
    onCancel: () => void;
};
export default function NewPasswordForm({
    newPassword,
    setNewPassword,
    onSubmit,
    onCancel,
}: Props) {
    const [isFormTouched, setIsFormTouched] = useState(false);

    return (
        <form className="flex flex-col w-full gap-y-8 text-left">
            <div className="my-12">
                <h3 className="font-bold text-true-blue">Reset new password</h3>
                <h4 className="text-faded-blue-600 py-2 font-light text-xs">
                    Since this is your first time logging in to PDT, you need to
                    create a new password. Make sure your new password:
                </h4>
            </div>
            <NInput
                label="New password"
                placeholder=""
                required
                modelValue={newPassword}
                onChange={(event) => {
                    event.preventDefault();

                    setIsFormTouched(true);
                    setNewPassword(event.target.value);
                }}
                icon="lock"
                size="md"
                variant="fill"
                type="password"
                invalid={
                    isFormTouched && newPassword.length < MIN_PASSWORD_LENGTH
                }
            />
            <NButton
                className="mt-12 text-xs"
                variant="fill"
                size="sm"
                type="submit"
                onClick={onSubmit}
            >
                Send recovery code
            </NButton>
            <NButton
                className="mt-4 text-xs"
                variant="outline"
                size="sm"
                onClick={onCancel}
            >
                Cancel
            </NButton>
        </form>
    );
}
