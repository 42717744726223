const DEAD_ROUTES = ['/error', '/logout'];
/**
 * determines if the current page is static or in fact we can navigate to somewhere else
 * @param pathname location path name
 * @returns if we are in dead end pages like error or logout, the program should not be navigating anywhere, for example, the login page
 */
export function isNavigable(pathname: string) {
    return !DEAD_ROUTES.some((route) => {
        return pathname.toLowerCase().startsWith(route);
    });
}
