import Dropzone, { FileWithPath } from 'react-dropzone';
import { formatBytes } from 'utils/fileUtils';
import { FileDropStatus } from 'utils/upload';
import { NButton, NIcon, NTooltip } from '@innersource-nn/novoui-react';
import LogoutIcon from '@mui/icons-material/Logout';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import { useNavigate } from 'react-router';

const PDT_CLI_DOCS =
    'https://dev.azure.com/novonordiskit/DMA%20DATAHUB/_wiki/wikis/DMA-DATAHUB.wiki/86801/PDT-CLI-Partner-User-Guide';

type FileDropProps = {
    onDrop: (acceptedFiles: FileWithPath[]) => void;
    onDropRejected: (fileStatuses: FileDropStatus[]) => void;
    uploadLimit: number;
    email?: string;
    bucket: string;
};

export function FileDrop({
    onDrop,
    onDropRejected,
    uploadLimit,
    bucket,
    email,
}: FileDropProps) {
    const navigate = useNavigate();

    function handleLogout() {
        navigate('/logout');
    }

    return (
        <div className="fileUploader">
            <div className="px-0 py-2.5">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-[7px]">
                        <NIcon
                            iconName="person"
                            size={32}
                            className="p-8 bg-light-blue-200 rounded-full"
                            fill="rgb(0, 25, 101)"
                        />
                        <span className="text-xs">{email}</span>
                    </div>
                    <NTooltip
                        content="Log out"
                        key="logout-button"
                        placement="up"
                    >
                        <LogoutIcon
                            className="p-4 border-[1px] rounded-lg"
                            style={{
                                width: '24px',
                                height: '24px',
                            }}
                            onClick={handleLogout}
                        />
                    </NTooltip>
                </div>
            </div>
            <hr className="divider -mx-20" />
            <div className="font-bold text-left py-2.5 mb-4">{bucket}</div>
            <Dropzone
                onDrop={onDrop}
                onDropRejected={onDropRejected}
                minSize={0}
                maxSize={uploadLimit}
                multiple
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                        {...getRootProps()}
                        className={`rounded-xl px-6 py-48 border-[1px] border-dashed text-xs ${isDragActive ? 'border-light-blue' : 'border-true-blue'}`}
                        data-testid="dropzone"
                    >
                        <input {...getInputProps()} />
                        <p className="mb-8">
                            <NIcon
                                iconName="upload"
                                size={32}
                                className="p-[6px] bg-sea-blue-600 rounded-full mx-auto"
                                fill="white"
                            />
                        </p>
                        <p>
                            Drag & drop or{' '}
                            <span className="text-sea-blue-600">
                                choose files
                            </span>{' '}
                            to transfer
                        </p>
                        <p className="mt-4 text-[10px] opacity-70">
                            Max total file size is {formatBytes(uploadLimit)}{' '}
                            per session
                        </p>
                    </div>
                )}
            </Dropzone>
            <div className="bg-granite-grey-100 p-20 text-xs rounded-lg text-left mt-20">
                <h4 className="font-bold mb-8">
                    Uploading a file larger than {formatBytes(uploadLimit)}?
                </h4>
                <div>
                    To upload larger files without this size restriction, you
                    can use our PDT CLI. (Requires local installation)
                </div>
                <NButton
                    className="w-full text-xs mt-12"
                    variant="outline"
                    size="xs"
                    type="submit"
                    onClick={() => {
                        window.open(PDT_CLI_DOCS, '_blank');
                        return false;
                    }}
                >
                    <OpenInNewIcon
                        style={{
                            width: '14px',
                            height: '14px',
                        }}
                    />
                    Get started with PDT CLI
                </NButton>
            </div>
        </div>
    );
}
