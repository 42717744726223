import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { isNavigable } from 'utils/nav_utils';

const UNLOCKED_ROUTES = ['/auth'];

const isUnlockedRoute = (pathname: string) => {
    return UNLOCKED_ROUTES.some((route) => {
        return pathname.startsWith(route);
    });
};

export default function useSecureApp({
    loadingSession,
    cognitoUserId,
}: {
    loadingSession: boolean;
    cognitoUserId?: string;
}) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loadingSession) {
            if (!isNavigable(location.pathname)) {
                return;
            }
            if (!cognitoUserId && !isUnlockedRoute(location.pathname)) {
                navigate('/auth/signin');
            } else if (cognitoUserId && isUnlockedRoute(location.pathname)) {
                navigate('/');
            }
        }
    }, [cognitoUserId, loadingSession, location.pathname, navigate]);
}
