import { CONFIRM_RESET_PASSWORD_WITH_CODE, DONE } from 'views/Auth/steps';
import { ResetPasswordOutput } from '@aws-amplify/auth/src/providers/cognito/types';

export default function triggerResetPasswordNextStep(
    nextStep: ResetPasswordOutput['nextStep'] | null,
    effect: () => void,
) {
    if (nextStep) {
        switch (nextStep.resetPasswordStep) {
            case CONFIRM_RESET_PASSWORD_WITH_CODE:
                effect();
                break;

            case DONE:
                effect();
                break;
        }
    }
}
