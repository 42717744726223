import { useMemo } from 'react';
import { UploadEntry } from 'utils/upload';

export default function useTransferData(uploads: UploadEntry[]) {
    return useMemo(() => {
        if (uploads.length === 0) {
            return { notStartedCount: 0, progress: 0, inProgressObjects: [] };
        }

        const sumOfFinished = uploads
            .filter(
                (entry) => entry.status === 'Done' || entry.status === 'Failed',
            )
            .reduce((sum, entry) => sum + entry.file.size, 0);

        const notStartedCount = uploads.filter(
            (entry) => entry.status === 'NotStarted',
        ).length;

        const totalFileSize = uploads.reduce(
            (sum, entry) => sum + entry.file.size,
            0,
        );

        const progress = (sumOfFinished * 100.0) / totalFileSize;

        const inProgressObjects = uploads
            .filter((entry) => entry.status === 'InProgress')
            .map((entry) => entry.objectKey);

        return { notStartedCount, progress, inProgressObjects };
    }, [uploads]);
}
