import React, { useState } from 'react';
import { NInput, NButton } from '@innersource-nn/novoui-react';
import { MIN_EMAIL_LENGTH } from 'views/Auth/utils/validation';

type Props = {
    username: string;
    setUsername: (newUsername: string) => void;
    onSubmit: () => void;
    onCancel: () => void;
};

export default function ForgotPasswordForm({
    username,
    setUsername,
    onSubmit,
    onCancel,
}: Props) {
    const [isFormTouched, setIsFormTouched] = useState(false);

    return (
        <form className="flex flex-col w-full gap-y-8 text-left">
            <div className="my-12">
                <h3 className="font-bold text-true-blue">Forgot password</h3>
                <h4 className="text-faded-blue-600 py-2 font-light text-xs">
                    Enter the email associated with your account and we will
                    send a 6-digit recovery code to your email.
                </h4>
            </div>
            <NInput
                label="Email adress"
                placeholder=""
                required
                modelValue={username}
                onChange={(event) => {
                    event.preventDefault();

                    setIsFormTouched(true);
                    setUsername(event.target.value);
                }}
                icon="lock"
                size="md"
                variant="fill"
                invalid={isFormTouched && username.length < MIN_EMAIL_LENGTH}
            />
            <NButton
                className="mt-12 text-xs"
                variant="fill"
                size="sm"
                type="submit"
                onClick={() => {
                    setIsFormTouched(true);
                    onSubmit();
                }}
            >
                Send recovery code
            </NButton>
            <NButton
                className="mt-4 text-xs"
                variant="outline"
                size="sm"
                onClick={onCancel}
            >
                Cancel
            </NButton>
        </form>
    );
}
