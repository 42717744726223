import React, { useEffect, useState } from 'react';
import { NNotification } from '@innersource-nn/novoui-react';
import { clearStorage } from 'views/Auth/hooks/usePoolDetails';
import { signOut } from 'aws-amplify/auth';
import MissingPoolPage from 'views/MissingPool/MissingPoolPage';
import { NotificationType } from '@novonordisk/novoui-vuejs/dist/components/NNotification';
import isEqual from 'lodash.isequal';

export type Message = {
    message: string;
    type: NotificationType;
};

const INITIAL_MESSAGE: Message = {
    message: 'Logging out....',
    type: 'info',
};

export function processLogout(
    setMessage: (m?: Message) => void,
    message?: Message,
) {
    // the only time we bother with the signout process is if the current message is the initial message we started with
    if (isEqual(message, INITIAL_MESSAGE)) {
        try {
            clearStorage();
        } catch (error) {
            setMessage({
                message: 'Failed to sign out',
                type: 'warning',
            });
            console.error(error);
            return;
        }

        signOut()
            .then(() => {
                setMessage({
                    message:
                        'You are now logged out. To login again, please use the link from your welcome email.',
                    type: 'info',
                });
            })
            .catch((error) => {
                console.error(error);
                setMessage({
                    message: 'Failed to sign out',
                    type: 'warning',
                });
            });
    }
}

export default function LogoutPage() {
    const [message, setMessage] = useState<Message | undefined>(
        INITIAL_MESSAGE,
    );

    useEffect(() => {
        processLogout(setMessage, message);
    }, [message]);

    function handleCloseMessage() {
        setMessage(undefined);
    }

    return (
        <>
            {message && (
                <div className="fixed inset-x-0 top-8 flex items-center justify-center z-50">
                    <NNotification
                        content={(message || INITIAL_MESSAGE).message}
                        type={(message || INITIAL_MESSAGE).type}
                        onClose={handleCloseMessage}
                    />
                </div>
            )}
            <MissingPoolPage />
        </>
    );
}
