import React, { useState } from 'react';
import FormHeader from 'views/Auth/components/FormHeader';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';

import RecoveryCodeForm from './RecoveryCodeForm';
import NewPasswordForm from './NewPasswordForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useNavigate } from 'react-router';
import triggerResetPasswordNextStep from 'views/Auth/utils/triggerResetPasswordNextStep';

enum ForgotPasswordStep {
    FORGOT_PASSWORD,
    RECOVERY_CODE,
    NEW_PASSWORD,
}

export default function ForgotPassword() {
    const [step, setStep] = useState(ForgotPasswordStep.FORGOT_PASSWORD);

    const [username, setUsername] = useState('');
    const [recoveryCode, setRecoveryCode] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const navigate = useNavigate();

    const onForgotPasswordSubmit = async () => {
        const result = await resetPassword({
            username,
        });

        triggerResetPasswordNextStep(result.nextStep, () =>
            setStep(ForgotPasswordStep.RECOVERY_CODE),
        );
    };

    const onRecoveryCodeSubmit = () => {
        setStep(ForgotPasswordStep.NEW_PASSWORD);
    };

    const onNewPasswordSubmit = async () => {
        await confirmResetPassword({
            username,
            confirmationCode: recoveryCode,
            newPassword,
        });

        triggerResetPasswordNextStep(
            null,
            () => () => navigate('/auth/signin'),
        );
    };

    const renderFormStep = () => {
        switch (step) {
            case ForgotPasswordStep.FORGOT_PASSWORD:
            default:
                return (
                    <ForgotPasswordForm
                        username={username}
                        setUsername={setUsername}
                        onSubmit={onForgotPasswordSubmit}
                        onCancel={() => navigate(-1)}
                    />
                );
            case ForgotPasswordStep.RECOVERY_CODE:
                return (
                    <RecoveryCodeForm
                        recoveryCode={recoveryCode}
                        setRecoveryCode={setRecoveryCode}
                        onSubmit={onRecoveryCodeSubmit}
                        onCancel={() =>
                            setStep(ForgotPasswordStep.FORGOT_PASSWORD)
                        }
                    />
                );
            case ForgotPasswordStep.NEW_PASSWORD:
                return (
                    <NewPasswordForm
                        newPassword={newPassword}
                        setNewPassword={setNewPassword}
                        onSubmit={onNewPasswordSubmit}
                        onCancel={() =>
                            setStep(ForgotPasswordStep.RECOVERY_CODE)
                        }
                    />
                );
        }
    };

    return (
        <div className="flex flex-col">
            <FormHeader />
            {renderFormStep()}
        </div>
    );
}
