import React from 'react';
import { useSearchParams } from 'react-router-dom';
import FormHeader from 'views/Auth/components/FormHeader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { NButton } from '@innersource-nn/novoui-react';
import { useNavigate } from 'react-router';

interface FallbackErrorProps {
    errorMessage: string | null;
}

const FallbackError: React.FC<FallbackErrorProps> = ({ errorMessage }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const message = errorMessage || searchParams.get('msg');
    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div className="flex flex-col">
            <FormHeader />
            <div>
                <ErrorOutlineIcon />
            </div>
            <h1 className="text-2xl font-bold leading-10">
                Something went wrong!
            </h1>
            <p className="text-xs">
                We're sorry, an unexpected error has occurred in our systems.
                Please try one of the options below or come back later.
            </p>
            {message && (
                <div>
                    <pre>{message}</pre>
                </div>
            )}
            <NButton
                className="text-xs"
                variant="outline"
                size="sm"
                type="submit"
                onClick={handleGoHome}
            >
                Return to homepage
            </NButton>
        </div>
    );
};

export default FallbackError;
