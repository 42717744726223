import { NButton, NIcon } from '@innersource-nn/novoui-react';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';

import { Box } from '@mui/material';
import React from 'react';

type TransferCompleteProps = {
    downloadStatus: () => void;
    restart: () => void;
    successfulCount: number;
    totalCount: number;
    bucket: string;
};

type Message = {
    title: string;
    message: string;
    icon: React.JSX.Element;
};

function determineMessage(
    successfulCount: number,
    totalCount: number,
    bucket: string,
): Message {
    if (totalCount === successfulCount) {
        return {
            title: 'Transfer successful!',
            message: `You have successfully transferred ${successfulCount} files to ${bucket}`,
            icon: (
                <div className="w-fit mx-auto p-12 bg-forest-green-200 rounded-full">
                    <CheckCircleIcon
                        style={{
                            width: '34px',
                            height: '34px',
                        }}
                    />
                </div>
            ),
        };
    }
    if (successfulCount === 0) {
        return {
            title: 'Transfer failed',
            message: `None of the ${totalCount} was transferred to ${bucket}`,
            icon: (
                <div className="w-fit mx-auto p-12 bg-lava-red-200 rounded-full">
                    <DangerousIcon
                        style={{
                            width: '34px',
                            height: '34px',
                        }}
                    />
                </div>
            ),
        };
    }
    return {
        title: 'Partial Transfer successful!',
        message: `You have successfully transferred ${successfulCount} out of ${totalCount} to ${bucket}. View transfer summary for more details.`,
        icon: (
            <div className="w-fit mx-auto p-12 bg-golden-sun-200 rounded-full">
                <CheckCircleIcon
                    style={{
                        width: '34px',
                        height: '34px',
                    }}
                />
            </div>
        ),
    };
}

export function TransferComplete({
    downloadStatus,
    restart,
    bucket,
    successfulCount,
    totalCount,
}: TransferCompleteProps) {
    const message: Message = determineMessage(
        successfulCount,
        totalCount,
        bucket,
    );
    return (
        <div className="fileUploader py-20">
            {message.icon}
            <Box
                sx={{ fontWeight: 'bold', fontSize: '24px', marginTop: '6px' }}
            >
                {message.title}
            </Box>
            <Box sx={{ fontSize: '12px' }}>{message.message}</Box>
            <div className="flex flex-col w-3/4 mx-auto">
                <NButton
                    className="mt-12 text-xs w-full"
                    variant="outline"
                    size="sm"
                    type="submit"
                    onClick={downloadStatus}
                >
                    <NIcon iconName="download" size={14} />
                    Download transfer summary
                </NButton>
                <NButton
                    className="mt-8 text-xs w-full"
                    variant="outline"
                    size="sm"
                    type="submit"
                    onClick={restart}
                >
                    Transfer additional files
                </NButton>
            </div>
        </div>
    );
}
