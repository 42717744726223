import React, { FormEvent, useState } from 'react';
import NN_logo from 'assets/icons/nn_logo.svg';
import { NButton, NInput } from '@innersource-nn/novoui-react';
import { RECOVERY_CODE_LENGTH } from 'views/Auth/utils/validation';
import { confirmSignIn } from 'aws-amplify/auth';
import { triggerAuthNextStep } from 'views/Auth/utils';
import { useNavigate } from 'react-router';

export default function VerificationCodeForm() {
    const [verificationCode, setVerificationCode] = useState('');

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setSubmitError(false);

        try {
            const { nextStep } = await confirmSignIn({
                challengeResponse: verificationCode,
            });

            await triggerAuthNextStep(nextStep, navigate);
        } catch (error) {
            setSubmitError(true);
        }
    };

    return (
        <div className="p-16">
            <img
                className="mx-auto h-[72px] w-[72px]"
                src={NN_logo}
                alt="NN-logo"
            />
            <div className="flex flex-col">
                <h1 className="font-bold text-true-blue text-3xl">
                    Partner Data Transfer
                </h1>
                <h4 className="text-faded-blue-600 py-4 font-light text-sm mb-16">
                    Transfer your data securely to Novo Nordisk
                </h4>
                <div className="mt-4 mb-12 text-center">
                    <h2 className="text-xl font-bold">
                        Enter verification code
                    </h2>
                    <p className="text-xs font-light pt-2">
                        Check your authentication app and enter the 6-digit code
                        to verify your login.
                    </p>
                </div>
                <form className="flex flex-col w-full gap-y-8">
                    <NInput
                        label="Authentication code"
                        placeholder=""
                        required
                        modelValue={verificationCode}
                        onChange={(event) => {
                            setIsFormTouched(true);
                            setVerificationCode(event.target.value);
                        }}
                        icon="lock"
                        size="md"
                        variant="fill"
                        invalid={
                            (isFormTouched &&
                                verificationCode.length !==
                                    RECOVERY_CODE_LENGTH) ||
                            submitError
                        }
                        assistiveText={
                            submitError
                                ? 'Invalid verification code. Please try again.'
                                : ''
                        }
                    />

                    <NButton
                        className="mt-12 text-xs"
                        variant="fill"
                        size="sm"
                        type="submit"
                        onClick={onSubmit}
                    >
                        Verify
                    </NButton>
                </form>
            </div>
        </div>
    );
}
