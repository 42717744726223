import { FileWithPath } from 'react-dropzone';
import { UploadEntry } from './upload';

const KB = 1024;
const SIZE_LABELS = [
    'B',
    'KiB',
    'MiB',
    'GB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
];

function calculateTotalSize(files: File[]) {
    return files.reduce((acc, file) => acc + file.size, 0);
}

function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 B';

    const i = Math.floor(Math.log(bytes) / Math.log(KB));

    return `${parseFloat((bytes / Math.pow(KB, i)).toFixed(decimals))} ${SIZE_LABELS[i]}`;
}

function getFilePath(file: FileWithPath, alternativePrefix: string): string {
    return file.path ?? alternativePrefix;
}

function removeInitialSlash(str: string): string {
    return str.startsWith('/') ? str.slice(1) : str;
}

function formatCsvValue(value: string): string {
    let formattedValue = value;

    // Check if the value contains a comma
    if (value.includes(',')) {
        // Wrap the value in double quotes
        formattedValue = `"${value.replace(/"/g, '""')}"`;
    } else if (formattedValue.includes('"')) {
        // Condition added here
        // Escape any existing double quotes
        formattedValue = formattedValue.replace(/"/g, '""');
    }

    return formattedValue;
}

function createCSVContent(entries: UploadEntry[]): string {
    // Create the header row
    const header = ['Local File Name', 'S3 Object key', 'Status'].join(',');

    // Create the data rows
    const dataRows = entries.map((entry) => {
        const values = [entry.file.name, entry.objectKey, entry.status];
        return values.map((value) => formatCsvValue(value)).join(',');
    });

    // Combine the header and data rows
    return [header, ...dataRows].join('\n');
}

export {
    calculateTotalSize,
    formatBytes,
    getFilePath,
    removeInitialSlash,
    createCSVContent,
};
