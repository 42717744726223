import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

function updateStorage(id: StorageId, searchParams: URLSearchParams) {
    const memoValue = searchParams.get(id) || localStorage.getItem(id);
    if (memoValue) {
        localStorage.setItem(id, memoValue);
    }
    return memoValue;
}

enum StorageId {
    CLIENT_ID = 'CLIENT_ID',
    USER_POOL_ID = 'USER_POOL_ID',
    REGION = 'REGION',
    IDENTITY_POOL_ID = 'IDENTITY_POOL_ID',
    DATASET = 'DATASET',
}

export function clearStorage() {
    for (const value of Object.values(StorageId)) {
        localStorage.removeItem(value);
    }
}

export default function usePoolDetails() {
    const [searchParams] = useSearchParams();

    return useMemo(() => {
        return {
            userPoolClientId: updateStorage(StorageId.CLIENT_ID, searchParams),
            userPoolId: updateStorage(StorageId.USER_POOL_ID, searchParams),
            region: updateStorage(StorageId.REGION, searchParams),
            identityPoolId: updateStorage(
                StorageId.IDENTITY_POOL_ID,
                searchParams,
            ),
            dataset: updateStorage(StorageId.DATASET, searchParams),
        };
    }, [searchParams]);
}
