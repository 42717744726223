import { useMemo } from 'react';
import { MIN_PASSWORD_LENGTH } from 'views/Auth/utils/validation';

export default function usePasswordValidation(password: string) {
    return useMemo(
        () => [
            {
                text: `Is longer or equal ${MIN_PASSWORD_LENGTH} characters`,
                condition: password.length >= MIN_PASSWORD_LENGTH,
            },
            {
                text: 'Contains both a lowercase and uppercase character',
                condition: /[a-z]/.test(password) && /[A-Z]/.test(password),
            },
            {
                text: 'Contains a number and symbol',
                condition: /[\W_\s]/.test(password) && /[0-9]/.test(password),
            },
        ],
        [password],
    );
}
