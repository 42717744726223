import React, { FormEvent, useEffect, useState } from 'react';
import { NButton, NInput } from '@innersource-nn/novoui-react';
import FormHeader from 'views/Auth/components/FormHeader';
import { useNavigate } from 'react-router';
import {
    sendUserAttributeVerificationCode,
    confirmUserAttribute,
} from 'aws-amplify/auth';
import { RECOVERY_CODE_LENGTH } from 'views/Auth/utils';

export default function VerifyEmailForm() {
    const [verificationCode, setVerificationCode] = useState('');

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        sendUserAttributeVerificationCode({
            userAttributeKey: 'email',
        });
    }, []);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setIsFormTouched(true);
        setSubmitError(false);

        try {
            confirmUserAttribute({
                userAttributeKey: 'email',
                confirmationCode: verificationCode,
            }).then(() => {
                navigate('/');
            });
        } catch (error) {
            setSubmitError(true);
        }
    };

    return (
        <div className="flex flex-col">
            <FormHeader />
            <form className="flex flex-col w-full gap-y-8 text-left">
                <div className="my-12">
                    <h3 className="font-bold text-true-blue">
                        Verify your email address
                    </h3>
                    <h4 className="text-faded-blue-600 py-2 font-light text-xs">
                        We have just sent you a 6-digit verification code to
                        your email in order to verify your email address. Please
                        enter it below:
                    </h4>
                </div>
                <NInput
                    label="Verification code"
                    placeholder=""
                    required
                    modelValue={verificationCode}
                    onChange={(event) => {
                        event.preventDefault();

                        setIsFormTouched(true);
                        setVerificationCode(event.target.value);
                    }}
                    icon="lock"
                    size="md"
                    variant="fill"
                    type="password"
                    invalid={
                        (isFormTouched &&
                            verificationCode.length !== RECOVERY_CODE_LENGTH) ||
                        submitError
                    }
                    assistiveText={
                        submitError
                            ? 'Invalid verification code. Please try again.'
                            : ''
                    }
                />
                <NButton
                    className="mt-12 text-xs"
                    variant="fill"
                    size="sm"
                    type="submit"
                    onClick={onSubmit}
                >
                    Verify email address
                </NButton>
                <NButton
                    className="mt-4 text-xs"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Cancel
                </NButton>
            </form>
        </div>
    );
}
