import { UploadEntry } from '../../../utils/upload';
import { Box, LinearProgress, Typography } from '@mui/material';
import { NButton } from '@innersource-nn/novoui-react';
import useTransferData from 'views/FileTransfer/hooks/useTransferData';

type TransferStatusProps = {
    uploads: UploadEntry[];
    onTransferCancel: () => void;
};

export default function TransferStatus({
    uploads,
    onTransferCancel,
}: TransferStatusProps) {
    const { notStartedCount, progress, inProgressObjects } =
        useTransferData(uploads);

    return (
        <>
            <div className="fileUploader rounded-xl px-12 py-20 border-[1px] border-dashed border-true-blue">
                <Box
                    sx={{ weight: 'bold', fontSize: '12px', textAlign: 'left' }}
                >
                    Transferring your files...
                </Box>
                <Box sx={{ fontSize: '11px', textAlign: 'left' }}>
                    {notStartedCount} files remaining:{' '}
                    {inProgressObjects.length === 1 ? inProgressObjects[0] : ''}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                        />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                        >{`${Math.round(progress)}%`}</Typography>
                    </Box>
                </Box>
            </div>
            <div className="text-left">
                <NButton
                    className="mt-12 text-xs"
                    variant="outline"
                    size="sm"
                    type="submit"
                    onClick={onTransferCancel}
                >
                    Cancel transfer
                </NButton>
            </div>
        </>
    );
}
