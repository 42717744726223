import { createContext } from 'react';
import { NotificationType } from '@novonordisk/novoui-vuejs/dist/components/NNotification';

export type NotificationInfo = {
    value: string;
    type?: NotificationType;
};

interface ErrorContextValue {
    setErrorMessage: (value: string, type?: NotificationType) => void;
}

const defaultErrorContext: ErrorContextValue = {
    setErrorMessage: (value: string, type: NotificationType = 'warning') => {
        console.log(value, type);
    },
};

export const ErrorContext =
    createContext<ErrorContextValue>(defaultErrorContext);
