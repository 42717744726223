import { useEffect, useState } from 'react';
import { fetchAuthSession, AuthSession } from 'aws-amplify/auth';
import { useLocation } from 'react-router';
import AWS from 'aws-sdk';
import usePoolDetails from 'views/Auth/hooks/usePoolDetails';
import { Amplify } from 'aws-amplify';

export default function useSession() {
    const [session, setSession] = useState<AuthSession>();
    const [loading, setLoading] = useState(true);

    const [cognitoUserId, setCognitoUserId] = useState<string>();

    const { identityPoolId, region, userPoolId, userPoolClientId } =
        usePoolDetails();

    const location = useLocation();

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: userPoolClientId || '',
                userPoolId: userPoolId || '',
            },
        },
    });

    AWS.config.region = region!;

    useEffect(() => {
        setLoading(true);
        const cognitoIdentity = new AWS.CognitoIdentity();

        fetchAuthSession()
            .then((s) => {
                setSession(s);

                const cognitoParams = {
                    IdentityPoolId: identityPoolId!,
                    Logins: {
                        [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]:
                            s?.tokens?.idToken?.toString()!,
                    },
                };

                cognitoIdentity
                    .getId(cognitoParams)
                    .promise()
                    .then((response) => {
                        setCognitoUserId(response.IdentityId);
                        AWS.config.credentials =
                            new AWS.CognitoIdentityCredentials(cognitoParams);
                    })
                    .catch((error) => {
                        console.log('[Cognito identity error]', { error });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((error) => {
                console.log('[Session error]', { error });
                setLoading(false);
            });
    }, [identityPoolId, location.pathname, region, userPoolId]);

    return {
        session,
        loading,
        cognitoUserId,
        userPoolId,
        userPoolClientId,
        cognitoEmail: session?.tokens?.idToken?.payload['email'] as string,
    };
}
