import React from 'react';
import NN_logo from 'assets/icons/nn_logo.svg';

export default function MissingPoolPage() {
    return (
        <div className="p-16">
            <img
                className="mx-auto h-[72px] w-[72px]"
                src={NN_logo}
                alt="NN-logo"
            />
            <div className="flex flex-col mt-4">
                <h1 className="font-bold text-true-blue text-3xl">
                    Partner Data Transfer
                </h1>
                <h4 className="text-faded-blue-600 py-2 font-light text-sm">
                    Transfer your data securely to Novo Nordisk
                </h4>
                <div className="flex flex-col w-full gap-y-12 text-left mt-20 mb-12">
                    <div className="bg-light-blue-200 p-20 text-xs rounded-lg">
                        <h4 className="font-bold mb-8">Important notice:</h4>
                        <div>
                            To begin uploading your files, please follow the
                            link that was included in your welcome email. This
                            link will take you to the correct login page.
                        </div>
                    </div>
                </div>
                <div className="text-[10px] px-20 opacity-50">
                    If you cannot find the email or are having trouble accessing
                    the link, please contact your Novo nordisk contact.
                </div>
            </div>
        </div>
    );
}
