import React, { FormEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { confirmSignIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router';
import { NInput, NButton } from '@innersource-nn/novoui-react';

import FormHeader from 'views/Auth/components/FormHeader';
import { RECOVERY_CODE_LENGTH } from 'views/Auth/utils';

export default function SetupTwoFactorForm() {
    const [verificationCode, setVerificationCode] = useState('');
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const qrCodeURI = searchParams.get('URI');

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setIsFormTouched(true);
        setSubmitError(false);

        try {
            confirmSignIn({
                challengeResponse: verificationCode,
            }).then(() => {
                navigate('/'); // TODO: Add email confirmation step
            });
        } catch (error) {
            setSubmitError(true);
        }
    };

    return (
        <div className="flex flex-col">
            <FormHeader />
            <form className="flex flex-col w-full gap-y-8 text-left">
                <div className="my-12">
                    <h3 className="font-bold text-true-blue">
                        Setup Two-factor authentication
                    </h3>
                    <h4 className="text-faded-blue-600 py-2 font-light text-xs">
                        1. Download any authentication app you prefer on your
                        phone (e.g. Microsoft or Google authenticator). Then,
                        open your authenticator app and scan the following QR
                        Code:
                    </h4>
                    <div className="pt-12 pb-4 flex justify-center">
                        <QRCodeSVG value={qrCodeURI || ''} />
                    </div>
                </div>
                <h4 className="text-faded-blue-600 py-2 font-light text-xs">
                    2. Enter the 6-digit verification generated below:
                </h4>
                <NInput
                    label="Verification code"
                    placeholder=""
                    required
                    modelValue={verificationCode}
                    onChange={(event) => {
                        event.preventDefault();

                        setIsFormTouched(true);
                        setVerificationCode(event.target.value);
                    }}
                    icon="lock"
                    size="md"
                    variant="fill"
                    invalid={
                        isFormTouched &&
                        verificationCode.length !== RECOVERY_CODE_LENGTH
                    }
                    assistiveText={
                        submitError
                            ? 'Incorrect verification code. Please try again.'
                            : ''
                    }
                />
                <NButton
                    className="mt-12 text-xs"
                    variant="fill"
                    size="sm"
                    type="submit"
                    onClick={onSubmit}
                >
                    Setup authenticator
                </NButton>
                <NButton
                    className="mt-4 text-xs"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Cancel
                </NButton>
            </form>
        </div>
    );
}
