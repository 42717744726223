import FileUpload from 'views/FileTransfer/components/FileUpload';
import { UPLOAD_LIMIT } from 'utils/limits';

import React from 'react';
import FormHeader from 'views/Auth/components/FormHeader';

import Loader from 'components/Loader';
import { useSession } from 'views/Auth/hooks';
import usePoolDetails from 'views/Auth/hooks/usePoolDetails';

function FileTransferPage() {
    const { cognitoUserId, cognitoEmail, loading } = useSession();
    const { dataset } = usePoolDetails();

    return (
        <div className="App-content">
            <FormHeader />
            {!loading ? (
                <FileUpload
                    {...{
                        bucket: dataset!,
                        email: cognitoEmail,
                        prefix: `${cognitoUserId}-${cognitoEmail}`,
                        uploadLimit: UPLOAD_LIMIT,
                    }}
                ></FileUpload>
            ) : (
                <Loader />
            )}
        </div>
    );
}

export default FileTransferPage;
