import React, { useState } from 'react';
import { NInput, NButton } from '@innersource-nn/novoui-react';
import { RECOVERY_CODE_LENGTH } from 'views/Auth/utils/validation';

type Props = {
    recoveryCode: string;
    setRecoveryCode: (recoveryCode: string) => void;
    onSubmit: () => void;
    onCancel: () => void;
};

export default function RecoveryCodeForm({
    recoveryCode,
    setRecoveryCode,
    onSubmit,
    onCancel,
}: Props) {
    const [isFormTouched, setIsFormTouched] = useState(false);

    return (
        <form className="flex flex-col w-full gap-y-8 text-left">
            <div className="my-12">
                <h3 className="font-bold text-true-blue">
                    Enter recovery code
                </h3>
                <h4 className="text-faded-blue-600 py-2 font-light text-xs">
                    Enter the 6-digit recovery code we just sent to your email
                    down below
                </h4>
            </div>
            <NInput
                label="Recovery code"
                placeholder=""
                required
                modelValue={recoveryCode}
                onChange={(event) => {
                    event.preventDefault();

                    setIsFormTouched(true);
                    setRecoveryCode(event.target.value);
                }}
                icon="lock"
                size="md"
                variant="fill"
                invalid={
                    isFormTouched &&
                    recoveryCode.length !== RECOVERY_CODE_LENGTH
                }
            />
            <NButton
                className="mt-12 text-xs"
                variant="fill"
                size="sm"
                type="submit"
                onClick={() => {
                    setIsFormTouched(true);
                    onSubmit();
                }}
            >
                Verify recovery code
            </NButton>
            <NButton
                className="mt-4 text-xs"
                variant="outline"
                size="sm"
                onClick={onCancel}
            >
                Cancel
            </NButton>
        </form>
    );
}
