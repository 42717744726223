import React, { FormEvent, useReducer, useState } from 'react';
import NN_logo from 'assets/icons/nn_logo.svg';
import { NButton, NInput } from '@innersource-nn/novoui-react';
import { NavLink } from 'react-router-dom';
import { signIn } from 'aws-amplify/auth';
import {
    MIN_EMAIL_LENGTH,
    MIN_PASSWORD_LENGTH,
} from 'views/Auth/utils/validation';
import { triggerAuthNextStep } from 'views/Auth/utils';
import { useNavigate } from 'react-router';

type FormField = {
    touched: boolean;
    value: string;
};

type FormState = {
    username: FormField;
    password: FormField;
};

type FormAction = {
    key: 'username' | 'password';
    value: string;
};

const DEFAULT_FORM = {
    username: {
        touched: false,
        value: '',
    },
    password: {
        touched: false,
        value: '',
    },
};

export default function SignInForm() {
    const [formData, setFormData] = useReducer(
        (state: FormState, action: FormAction): FormState => {
            const newState: FormState = { ...state };
            newState[action.key] = {
                touched: true,
                value: action.value,
            };
            return newState;
        },
        DEFAULT_FORM,
    );
    const [submitError, setSubmitError] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setSubmitError(false);

        try {
            const { nextStep } = await signIn({
                username: formData.username.value,
                password: formData.password.value,
                options: {
                    authFlowType: 'USER_PASSWORD_AUTH',
                },
            });

            await triggerAuthNextStep(nextStep, navigate);
        } catch (error) {
            console.error(error);
            setSubmitError(true);
        }
    };

    return (
        <div className="p-16">
            <img
                className="mx-auto h-[72px] w-[72px]"
                src={NN_logo}
                alt="NN-logo"
            />
            <div className="flex flex-col mt-4">
                <h1 className="font-bold text-true-blue text-3xl">
                    Partner Data Transfer
                </h1>
                <h4 className="text-faded-blue-600 py-2 font-light text-sm mb-20">
                    Transfer your data securely to Novo Nordisk
                </h4>
                <form className="flex flex-col w-full gap-y-12">
                    <NInput
                        label="Email"
                        placeholder=""
                        required
                        modelValue={formData.username.value}
                        onChange={(e) =>
                            setFormData({
                                key: 'username',
                                value: e.target.value,
                            })
                        }
                        icon="person"
                        variant="fill"
                        invalid={
                            (formData.username.touched &&
                                formData.username.value.length <
                                    MIN_EMAIL_LENGTH) ||
                            submitError
                        }
                        size="md"
                    />
                    <NInput
                        label="Password"
                        placeholder=""
                        required
                        modelValue={formData.password.value}
                        onChange={(e) =>
                            setFormData({
                                key: 'password',
                                value: e.target.value,
                            })
                        }
                        icon="lock"
                        type="password"
                        size="md"
                        variant="fill"
                        invalid={
                            (formData.password.touched &&
                                formData.password.value.length <
                                    MIN_PASSWORD_LENGTH) ||
                            submitError
                        }
                        assistiveText={
                            submitError
                                ? 'Incorrect email or password. Please try again.'
                                : ''
                        }
                    />

                    <div className="flex flex-col mt-8">
                        <NButton
                            className="text-xs"
                            variant="fill"
                            size="sm"
                            type="submit"
                            onClick={onSubmit}
                        >
                            Login
                        </NButton>
                        <NButton className="text-xs" variant="text" size="sm">
                            <NavLink
                                to="/auth/forgot-password"
                                className="flex items-center justify-center w-full h-full"
                            >
                                Forgot the password?
                            </NavLink>
                        </NButton>
                    </div>
                </form>
            </div>
        </div>
    );
}
