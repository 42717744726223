import { Component, ErrorInfo, ReactNode } from 'react';
import FallbackError from 'components/FallbackError';
import setGlobalErrorHandler from 'hooks/setGlobalErrorHander';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage: string | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        setGlobalErrorHandler();
        this.state = {
            hasError: false,
            errorMessage: null,
        };
    }

    static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: _error.message };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return <FallbackError errorMessage={this.state.errorMessage} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
