import NN_logo from 'assets/icons/nn_logo.svg';
import React from 'react';

export default function FormHeader() {
    return (
        <div className="flex flex-row justify-between items-center pb-12 border-b-1 -mx-20 px-20">
            <div className="text-left">
                <h2 className="font-bold text-true-blue text-lg my-4">
                    Partner Data Transfer
                </h2>
                <h4 className="text-faded-blue-600 font-light text-xs">
                    Transfer your data securely to Novo Nordisk
                </h4>
            </div>
            <div>
                <img
                    className="mx-auto h-[64px] w-[64px]"
                    src={NN_logo}
                    alt="NN-logo"
                />
            </div>
        </div>
    );
}
