import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import './fileUpload.css';
import { FileDropStatus, UploadEntry } from 'utils/upload';
import {
    gatherFileDropErrors,
    generateURLFromFileStatus,
    tryUpload,
    uploadFiles,
} from 'utils/upload_service';
import TransferStatus from './TransferStatus';
import { ErrorContext } from 'utils/errors';
import { TransferComplete } from 'views/FileTransfer/components/TransferComplete';
import { FileDrop } from 'views/FileTransfer/components/FileDrop';
import { ManagedUpload } from 'aws-sdk/clients/s3';

type FileUploadStatus = 'Ready' | 'InProgress' | 'Completed';

type FileUploadProps = {
    bucket: string;
    uploadLimit: number;
    email: string;
    prefix: string;
};

function FileUpload(props: FileUploadProps) {
    const { bucket, uploadLimit, prefix } = props;
    const [fileUploads, setFileUploads] = useState<UploadEntry[]>([]);
    const [uploader, setUploader] = useState<ManagedUpload>();
    const { setErrorMessage } = useContext(ErrorContext);
    const [status, setStatus] = useState<FileUploadStatus>('Ready');

    const onDrop = useCallback(
        (acceptedFiles: FileWithPath[]) =>
            uploadFiles(
                acceptedFiles,
                fileUploads,
                prefix,
                setErrorMessage,
                uploadLimit,
                setFileUploads,
            ),
        [fileUploads, prefix, setErrorMessage, uploadLimit],
    );

    const restart = useCallback(() => {
        setFileUploads([]);
        setStatus('Ready');
        setErrorMessage('');
    }, [setErrorMessage]);

    function onDownloadStatus() {
        const url = generateURLFromFileStatus(fileUploads);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'transfer_status.csv'; // Set the desired file name
        link.click();
        URL.revokeObjectURL(url);
    }

    function onDropRejected(fileStatuses: FileDropStatus[]) {
        setErrorMessage(gatherFileDropErrors(fileStatuses), 'alert');
    }

    useEffect(() => {
        tryUpload(fileUploads, bucket, setFileUploads, setUploader);
    }, [bucket, fileUploads]);

    useEffect(() => {
        const checkUploadStatus = () => {
            if (fileUploads.length === 0) {
                setStatus('Ready');
                return;
            }
            // Check if all uploads are completed
            const allUploadsCompleted = fileUploads.every(
                (upload) =>
                    upload.status === 'Done' || upload.status === 'Failed',
            );

            // Update the uploadComplete state based on the result
            if (allUploadsCompleted) {
                // If all uploads are completed, set the uploadComplete state to true
                setStatus('Completed');
            } else {
                setStatus('InProgress');
            }
        };

        checkUploadStatus();
    }, [fileUploads]);

    const successfulCount = fileUploads.filter(
        (entry) => entry.status === 'Done',
    ).length;

    function onTransferCancel() {
        if (uploader) uploader.abort();

        fileUploads.forEach((upload) => {
            if (upload.status !== 'Done') {
                upload.status = 'Failed';
            }
        });

        setStatus('Completed');
    }

    return status === 'Ready' ? (
        <FileDrop {...{ onDrop, onDropRejected, ...props }} />
    ) : status === 'InProgress' ? (
        <div>
            <div className="font-bold text-left py-2.5 mb-4">{bucket}</div>
            <TransferStatus
                uploads={fileUploads}
                onTransferCancel={onTransferCancel}
            />
        </div>
    ) : (
        <TransferComplete
            {...{
                bucket,
                restart,
                successfulCount,
                totalCount: fileUploads.length,
                downloadStatus: onDownloadStatus,
            }}
        />
    );
}

export default FileUpload;
