import { BrowserRouter } from 'react-router-dom';
import Router from 'views/Router';
import { useState } from 'react';
import { NNotification } from '@innersource-nn/novoui-react';
import { ErrorContext, NotificationInfo } from './utils/errors';
import ErrorBoundary from 'components/ErrorBoundary';
import MaintenancePage from 'views/Maintenance/MaintenancePage';
import { IN_MAINTENANCE } from './utils/app_states';

function App() {
    const [errorMessage, setErrorMessage] = useState<NotificationInfo>({
        value: '',
        type: 'warning',
    });

    const onCloseToaster = () => {
        setErrorMessage({
            ...errorMessage,
            value: '',
        });
    };

    // Set PDT App into Maintenance Mode
    return IN_MAINTENANCE ? (
        <div className="flex flex-col w-screen h-screen justify-center items-center bg-true-blue">
            <div className="rounded-xl bg-white px-20 pb-24 pt-16 text-center z-10 w-[400px]">
                <MaintenancePage />
            </div>
        </div>
    ) : (
        <div className="flex flex-col w-screen h-screen justify-center items-center bg-true-blue">
            {errorMessage.value && (
                <div className="fixed inset-x-0 top-8 flex items-center justify-center z-50">
                    <NNotification
                        content={errorMessage.value}
                        type={errorMessage.type}
                        onClose={onCloseToaster}
                    />
                </div>
            )}
            <ErrorBoundary>
                <ErrorContext.Provider
                    value={{
                        setErrorMessage: (value, type) =>
                            setErrorMessage({ value, type: type || 'warning' }),
                    }}
                >
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </ErrorContext.Provider>
            </ErrorBoundary>
        </div>
    );
}

export default App;
