import {
    CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED,
    CONTINUE_SIGN_IN_WITH_TOTP_SETUP,
    CONFIRM_SIGN_IN_WITH_TOTP_CODE,
    DONE,
} from 'views/Auth/steps';
import { SignInOutput } from '@aws-amplify/auth/src/providers/cognito/types';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

export default async function triggerAuthNextStep(
    nextStep: SignInOutput['nextStep'],
    navigate: NavigateFunction,
) {
    switch (nextStep.signInStep) {
        case CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED:
            navigate('/auth/new-password');
            break;

        case CONTINUE_SIGN_IN_WITH_TOTP_SETUP:
            const setupUri = nextStep.totpSetupDetails.getSetupUri(
                'Partner Data Transfer',
            );

            navigate(`/auth/setup-two-factor?URI=${setupUri}`);
            break;

        case CONFIRM_SIGN_IN_WITH_TOTP_CODE:
            navigate('/auth/verification-code');
            break;

        case DONE:
            navigate('/');
            break;
    }
}
