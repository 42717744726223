import React, { FormEvent, useState } from 'react';
import { NButton, NInput } from '@innersource-nn/novoui-react';
import FormHeader from 'views/Auth/components/FormHeader';
import PasswordValidation from 'views/Auth/components/PasswordValidation';
import { useNavigate } from 'react-router';

import { confirmSignIn } from 'aws-amplify/auth';
import { triggerAuthNextStep } from 'views/Auth/utils';

export default function CreateNewPasswordForm() {
    const [newPassword, setNewPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setIsFormTouched(true);
        setSubmitError(false);

        try {
            const { nextStep } = await confirmSignIn({
                challengeResponse: newPassword,
            });

            await triggerAuthNextStep(nextStep, navigate);
        } catch (error) {
            console.log(error);

            setSubmitError(true);
        }
    };

    return (
        <div className="flex flex-col">
            <FormHeader />
            <form className="flex flex-col w-full gap-y-8 text-left">
                <div className="my-12">
                    <h3 className="font-bold text-true-blue">
                        Create new password
                    </h3>
                    <h4 className="text-faded-blue-600 py-2 font-light text-xs">
                        Since this is your first time logging in to PDT, you
                        need to create a new password. Make sure your new
                        password:
                    </h4>
                </div>
                <NInput
                    label="New password"
                    placeholder=""
                    required
                    modelValue={newPassword}
                    onChange={(event) => {
                        event.preventDefault();

                        setIsFormTouched(true);
                        setNewPassword(event.target.value);
                    }}
                    icon="lock"
                    size="md"
                    variant="fill"
                    type="password"
                    invalid={isFormTouched && (!isPasswordValid || submitError)}
                    assistiveText={
                        submitError
                            ? 'The password does not fulfill the requirements'
                            : ''
                    }
                />
                <PasswordValidation
                    password={newPassword}
                    isFormTouched={isFormTouched}
                    setIsPasswordValid={setIsPasswordValid}
                />
                <NButton
                    className="mt-12 text-xs"
                    variant="fill"
                    size="sm"
                    type="submit"
                    onClick={onSubmit}
                >
                    Change password
                </NButton>
                <NButton
                    className="mt-4 text-xs"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Cancel
                </NButton>
            </form>
        </div>
    );
}
