import * as AWS from 'aws-sdk';
import { FileDropStatus, UploadEntry } from './upload';
import { Dispatch, SetStateAction } from 'react';
import {
    calculateTotalSize,
    createCSVContent,
    formatBytes,
    getFilePath,
    removeInitialSlash,
} from './fileUtils';
import { MULTIPART_SIZE, UPLOAD_LIMIT_GB } from './limits';
import { FileWithPath } from 'react-dropzone';
import { ManagedUpload } from 'aws-sdk/clients/s3';

// When no region or credentials are provided, the SDK will use the
// region and credentials from the local AWS config.

function upload(uploadFile: UploadEntry, bucketName: string) {
    // Uploading file to s3
    // Use S3 ManagedUpload class as it supports multipart uploads
    const uploader = new AWS.S3.ManagedUpload({
        partSize: MULTIPART_SIZE,
        queueSize: 1, // one multipart at a time
        params: {
            Bucket: bucketName,
            Key: uploadFile.objectKey,
            Body: uploadFile.file,
        },
    });

    return uploader;
}

const tryUpload = async (
    uploads: UploadEntry[],
    bucketName: string,
    setUploads: Dispatch<SetStateAction<UploadEntry[]>>,
    setUploader: Dispatch<SetStateAction<ManagedUpload | undefined>>,
) => {
    const candidate = uploads.find((u) => u.status === 'NotStarted');
    const inProgress = uploads.find((u) => u.status === 'InProgress');

    // If there is no candidate there is nothing to do
    if (!candidate || inProgress) return;

    setUploads((existing: UploadEntry[]) =>
        existing.map((e) =>
            e.file === candidate.file ? { ...e, status: 'InProgress' } : e,
        ),
    );

    try {
        const uploader = upload(candidate, bucketName);

        setUploader(uploader);

        await uploader.promise();

        setUploads((existing: UploadEntry[]) =>
            existing.map((e) =>
                e.file === candidate.file ? { ...e, status: 'Done' } : e,
            ),
        );
    } catch (error) {
        console.error(error);
        setUploads((existing: UploadEntry[]) =>
            existing.map((e) =>
                e.file === candidate.file
                    ? { ...e, status: 'Failed', error }
                    : e,
            ),
        );
    }
};

function generateURLFromFileStatus(fileUploads: UploadEntry[]) {
    const fileContent = createCSVContent(fileUploads);
    const blob = new Blob([fileContent], { type: 'text/plain' });
    return URL.createObjectURL(blob);
}

function uploadFiles(
    acceptedFiles: FileWithPath[],
    fileUploads: UploadEntry[],
    prefix: string,
    setErrorMessage: (x: string) => void,
    uploadLimit: number,
    setFileUploads: (entries: UploadEntry[]) => void,
) {
    const totalSize = calculateTotalSize(acceptedFiles);
    if (totalSize > uploadLimit) {
        setErrorMessage(
            `Total size ${formatBytes(totalSize)} exceeded the maximum of ${formatBytes(uploadLimit)}`,
        );
    } else {
        const concatUploads = [
            ...fileUploads,
            ...acceptedFiles.map(
                (file, index) =>
                    ({
                        file,
                        objectKey:
                            `${prefix}/` +
                            removeInitialSlash(
                                getFilePath(file, `${index}/${file.name}`),
                            ),
                        status: 'NotStarted',
                    }) as UploadEntry,
            ),
        ];
        setFileUploads(concatUploads);
    }
}

const CUSTOM_ERRORS: Record<string, string> = {
    'file-too-large': `The file you uploaded exceeds our maximum limit of ${UPLOAD_LIMIT_GB}GB. Please select files in batches or use the PDTCLI.`,
};

function gatherFileDropErrors(fileStatuses: FileDropStatus[]) {
    return (
        CUSTOM_ERRORS[fileStatuses[0].errors[0].code] ??
        Array.from(
            new Set(
                fileStatuses.flatMap((fileStatus) =>
                    fileStatus.errors.map((err) => err.message),
                ),
            ),
        ).join(',')
    );
}

export {
    tryUpload,
    upload,
    generateURLFromFileStatus,
    uploadFiles,
    gatherFileDropErrors,
};
