import React from 'react';
import { CircularProgress } from '@mui/material';

export default function Loader() {
    return (
        <div className="my-64">
            <CircularProgress size={80} />
        </div>
    );
}
